---
title: The Hagiography of Clean
date:
  - 5
  - dec
  - 2024
tags:
  - writing
language: en-GB
description: Article analysing cleanliness, the human condition, and social media.
---

                            The Hagiography of Clean
-------------------------------------------------------------------------------

What is the operation “to clean”, and where does its inherent virtuousness
spring from? If in cleaning something is made uglier, is it still cleaned?

In anonymous suburban America, a kind and cheerful twenty-thirty-something
makes content about cleaning up people’s driveways. There may be several of
these guys. I wouldn’t know – the formula is the same. The hours and minutes
dissolve away in a satisfying fast-forward dance.

What was once a grassy verge now reveals yellow cragged concrete. The
archaeologist perfectly frames the body of Lazarus, cutting away the grass at
edges to form a neat line of exposed soil I know now is called “edging”.

The big reveal – the house’s residents are surprised there was ever anything
there beneath that grass. Clean of moss and the occasional fleck of grass, the
driveway paints a stark contrast to the lawn either side that is positively
calligraphic, like the strokes of black ink a master leaves on white paper.

I watch back the reveal and the residents’ words sit uncomfortably as I digest
them. The expressions are hard to make out with the camera placed at this
distance. There is some surprise in their voices, but, whether I project it or
not, I feel a distinct twinge of apprehension buried under appearances being
kept up. I can’t help but feel the question burning in them as it does me. Is
this better?

It’s cleaner.

Is that better?

*******************************************************************************

Newton or someone like that once said that all closed systems must become more
entropic – more disordered over time.

It feels right to imagine that to clean is our attempt at the reverse of this
operation. The messy room mires the mind. The brain cannot reduce the colours
and the shapes. A loose cloth can be just that, or it could be hiding the pen
I’ve been looking for all damn week. Psychic energy is expended as I process my
writing-paper having fallen off the sofa onto the tray from yesterday’s
breakfast.

But reversing this is surely not simply as much as returning to what once was.
Few want the tree back as a sapling. A trim, perhaps. But what cleaning is to
cut down, and what cleaning is to trim the grass?

Is it always the cleaning itself that heals us, or can it be the knowledge that
we are now clean? If it was enough to have undone the day’s dirt a bath would
be simply a worse way to achieve what you can do with a shower.

All that is to say there is an undeniable therapeutic effect to cleaning which
goes beyond undoing what has been done. But that also especially in more
extreme forms, cleaning comes with the risk of losing what we might have wanted
to preserve whether we realised it at the time or not.

Pristine white walls and grey empty countertops are an externalisation of that
kind of therapy to me, with similar drawbacks. A blank wall is free from the
entropy of the past, but also free from what became of the past we may have
enjoyed in the present. It saves us from having to confront what of the past we
wanted to keep. The past is undone regardless, and the present is freed of its
baggage – both good and bad.

I think that cleaning is a form of construction. My dissatisfaction from my
driveway-by-proxy becoming clean stems from my dissatisfaction with what it
became:

We often believe, as the power-washers do, that what is cleaned is simply
better. Occasionally we are confronted with the reality that we are doing more
than curing the future — of the past and find ourselves confused by the present
we have constructed. The raccoon dips its candy floss in water expecting it to
come out better, [but instead it is gone][🦝].

[🦝]: https://www.youtube.com/watch?v=rfbb4yRBH64

*******************************************************************************

I don’t want this understood as an anti-clean analysis. During the pandemic, I
picked up a real penchant for long baths and [nice soaps] on difficult days and
I’m still that person today.

[nice soaps]:
    https://www.amazon.com/gp/aw/d/B0013IP7HY/?_encoding=UTF8&pd_rd_plhdr=t&aaxitk=149c2817e9a51ac957c7b0d0ebd68480&hsa_cr_id=0&qid=1733377302&sr=1-1-9e67e56a-6f64-441f-a281-df67fc737124&ref_=sbx_be_s_sparkle_lsi4d_asin_0_img&pd_rd_w=9h6mk&content-id=amzn1.sym.8591358d-1345-4efd-9d50-5bd4e69cd942%3Aamzn1.sym.8591358d-1345-4efd-9d50-5bd4e69cd942&pf_rd_p=8591358d-1345-4efd-9d50-5bd4e69cd942&pf_rd_r=W9DNT8RWM5AH6K7SDMAG&pd_rd_wg=WRhcz&pd_rd_r=d9139b6b-118c-4066-877d-bde8a24ac672
    "Pears Soap, Face & Body Soap, Amber – Pure & Gentle Transparent Bar Soap,
    Moisturizing Glycerin Soap with Natural Oils for Pampered, Glowing Skin,
    4.4 Oz (Pack of 12)"

Conversely, I’ve found happiness in the realisation that when I tidy my home,
my goal isn’t an empty table, but an ordered one. I used to move my pen into my
desk-drawer where I would lose it. Now, it has its own place in a little wooden
holder on my desk.

I hope that when you decide to clean, or not to, you ask what change it makes
and what that change means to you.

Thomas
