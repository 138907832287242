---
title: Missing
date:
  - 16
  - nov
  - 2024
tags:
  - writing
language: en-GB
description: Article on what it means to miss home after two years.
---

                                    Missing
-------------------------------------------------------------------------------

The trouble with missing your home country is that what you miss most intensely
is intangible. I left London for San Francisco at least two years ago now (I've
not been properly keeping track). This is long enough to miss the place I spent
the first 28 years of my life in and around.

Living in the future as we do today I have every ability to fly across the
ocean and be back there, but whenever I do, I never quite grasp what it is I
missed enough to feel all of what felt like it was missing. I never quite
manage to catch that feeling enough with both hands.

I think that when I turn those thoughts over in my mind – the things that
make me most miss home, the trouble is that they are not places, and they are
not things. They're little magical moments that happen by chance. The kinds
you trip over.

If a place is a larder, full with ingredients; some unique, some universal,
the meals I miss most are not the ones you can make on purpose. They're the
ones that one day happen upon you with ingredients you just picked up on
the way to something else.

Summers in England can be punishingly hot. The field grasses dry out almost to
hay while still in the soil. In the westcountry, the valleys these fields are
nestled in tend to funnel groundwater to springs. The waterways carve goyles
out of the soft soil at the high points and settle into brooks at the shallow
valley basins.

In that late spring period, these brooks become flush with water between the
ever-intermittent rain which falls thickly over the valley. In turn, these
brooks form natural field boundaries as the trees grow thick and healthily
around the water source. The hedgerows on both sides are pierced by clearings
for gates onto fords to cross the water.

In that transition space from parched field to parched field, the running water
cools the air. The thick trees ample coverage form dark pools of shadow around
the water. If you travel by these gulleys, you emerge in some other field
blinking from bright daylight.

I am sure back home, many of us have that experience of resting under a tree
next to a gate on one of these natural boundaries. I find myself missing the
experience, but I don't find myself seeking it out. It's not the activity I
miss, or a specific place – but simply a happening that you happen upon every
few years, and then one day, a world away on another continent, you simply
don't.

I miss also the bluebells that push up from the grass in the woodlands in
spring. As I understand it now, for many hundreds of years those woodlands were
worked for sticks and firewood, creating space for undergrowth like those tiny
flowers. There are places in England back home that are famous for this event,
and people seek out particularly grounds to find themselves in when this
happens.

It's not as though I couldn't go to one of these places at just the right time
and seek the experience out – but to fly 9-10 hours for that would be like
flying back for a particular church service at a particularly beautiful church.
These things are caught up with passing the time there, and to construct
international travel plans to experience such a beautiful, special, and yet
mundane moment dilutes its meaning.

I miss the snow in winter, and how it so palpably transforms every place. The
snow falls, and you re-visit every place you love wanting to see its new face.
But I don't miss the disappointment of a cold and yet snowless christmas the
same way I don't miss the disappointment of a summer that never quite aligns
with expectations.

The snow, like the transformation of late spring and summer has some of its
magic in recontextualising the life you already lead there. These experiences
are the sparse things that happen inevitably over 28 years. You look out of
your bedroom window and your everyday is shifted with the tide of the world.

I suppose in that sense, I miss it the way I miss the bread like back home – I
know that I can sit down to put the pieces together to access it, or something
like it; but it is the ease at which these things once happened that has me
longing.

*******************************************************************************

Two months ago, in September, I took the Night Riviera Sleeper over to
Penzance, near land's end – a strange, beautiful, sub-tropical part of the UK
much written about in famous Victorian novels.

It felt like by taking myself out to this place I managed to construct for
myself somehow some of that serendipity I write about that I missed. Ascending
St Michael's Mount, I felt more than I had done in a long time that feeling
that sits somewhere between those sparse moments I talk about.

I wonder why? Perhaps the newness and strangeness let me live in the moment
with them again.

Thomas
